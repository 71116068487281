.heirloom-tooltip {
    button {
        background-color: transparent;
        border: none;
        font-weight: 500;

        &:active, &:focus {
            border-width: 2px;
            outline: none;
        }
    }

    .helper-icon {
        align-items: center;
        background-color: transparent;
        border: 1px solid black;
        border-radius: 50%;
        display: inline-flex;
        font-size: 1.05rem;
        justify-content: center;
    }
}