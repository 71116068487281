.main {
    &__messaging {
        background-color: white;
        bottom: 2rem;
        left: 2rem;
        position: fixed;

        .toast-header {
            color: white;
        }

        .custom-header-success {
            background-color: var(--success);
        }

        .custom-header-error {
            background-color: var(--danger);
        }
    }
}