@import '../../../shared/styles/colors.scss';

.page-header {
    border-bottom: 1px solid $light-medium-gray;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    &__title {
        color: $heirloom-blue;
        margin-bottom: 1rem;
    }

    &__directory-link {
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 500;

        &:hover {
            color: $heirloom-dark-blue;
        }
    }

    &__directory-link--no-events {
        cursor: initial;
        opacity: 0.6;
        pointer-events: none;
    }

    @media screen and (min-width: 768px) {
        padding: 1rem 0;

        &__title {
            margin: 0;
        }
    }
}