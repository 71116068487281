$light-gray: #F9FAFB;
$light-medium-gray: #E5E5E5;
$medium-gray: #A3AAAE;

$heirloom-green: #33b5a9;
$heirloom-turqoise: #20B2AB;

$heirloom-blue: #1480C1;
$heirloom-lightblue: #e1e7fd;
$heirloom-dark-blue: #1B6FA2;
$heirloom-disabled-blue: #9BC6E3;