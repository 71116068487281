.heirloom-loading-screen {
    align-items: center;
    background-color: rgba(255,255,255,.5);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999999999999;
}