@import '../../shared/styles/colors.scss';

.site-header {
    background-color: $heirloom-blue;
    
    .navbar {
        padding: 0.5rem 0;

        .navbar-text {
            color: white;
            font-size: .8rem;
            font-weight: bold;
        }

        .navbar-toggler {
            border: none;
        } 

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }

        .navbar-nav {

            .nav-link {
                color: white;
                font-size: 1.25rem;
                margin-bottom: 0.5rem;

                &:hover {
                    color: $light-medium-gray;
                }
            }
        }
    }

    .navbar-light .navbar-nav .nav-link {
        color: white;

        &:hover, &:focus, &:active {
            color: white;
        }
    }

    &__logo {
        max-width: 15rem;
    }

    @media screen and (min-width: 768px) {
        .navbar {
            padding: 0.8rem 4rem 0.6rem 3rem;

            .navbar-text {
                position: absolute;
                right: calc(4rem + 15px);
                top: 0;
            }

            .navbar-nav {
                margin-top: 0.5rem;
    
                .nav-link {
                    font-size: 1rem;
                }
            }
        }
    }
}