@import './shared/fonts/fonts.scss';
@import './shared/styles/colors.scss';

html, body {
    font-family: 'Source Sans 3';
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Source Serif Pro';
}

#main {
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
}

h1 {
    font-size: 1.7rem;
}

h2 {
    font-size: 1.3rem;
}

a {
    color: $heirloom-blue;

    &:hover {
        color: $heirloom-dark-blue;
        text-decoration: none;
    }
}

button, a {
    &.btn-primary {
        background-color: $heirloom-blue;
        border-color: $heirloom-blue;

        &:hover {
            background-color: $heirloom-dark-blue;
            border-color: $heirloom-dark-blue;
        }

        &[disabled] {
            background-color: $heirloom-disabled-blue;
            border-color: $heirloom-disabled-blue;
        }
    }

    &.btn-info {
        background-color: $heirloom-lightblue;
        border-color: $heirloom-lightblue;
        color: black;
        font-weight: bold;

        &:hover, &:active, &:focus {
            background-color: $heirloom-blue !important;
            border-color: $heirloom-blue !important;
            box-shadow: 0 0 0 0.2rem rgba(20, 128, 193, .1);
            color: white;
        }
    }

    &.btn-success {
        background-color: $heirloom-green;
        border-color: $heirloom-green;
        color: white;

        &:hover, &:active, &:focus {
            background-color: $heirloom-turqoise !important;
            border-color: $heirloom-turqoise !important;
            box-shadow: 0 0 0 0.2rem rgba(20, 128, 193, .1);
            color: white;
        }
    }

    svg {
        margin-right: 0.25rem;
    } 
}

button.has-spinner {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    span {
        margin-left: 1rem;
    }
}