.guest-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;

    p {
        max-width: 720px;
    }
}