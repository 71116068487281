.account-manager {
    padding: 1rem;

    &__add-user {
        color: white;
    }

    &__user-details-wrapper.row {
        margin: 1rem auto;
        max-width: 50rem;
    }

    &__user-table-wrapper {
        max-width: 100%;
        overflow-x: scroll;
    }

    &__user-table {
        margin-top: 2rem;

        tr {
            cursor: pointer;
        }

        td {
            img {
                height: auto;
                width: 2rem;
            }
        }
    }

    &__error { 
        margin-top: 1rem;
    }

    @media screen and (min-width: 768px) {
        padding: 1rem calc(3rem + 15px);

        &__user-table-wrapper {
            overflow-x: hidden;
        }
    }
}