@import '../../../shared/styles/colors.scss';

.file-manager {
    align-items: stretch;
    display: flex;
    min-height: calc(100vh - 71px);
    padding: 0;

    &__check-files-links {
        display: flex;
        justify-content: flex-end;

        a {
            font-size: 0.9rem;
            font-weight: 600;

            &:last-child {
                margin-left: 1.25rem;
            }
        }
    }

    &__file-list-wrapper.col-lg-9 {
        padding: 1rem 2rem;
    }

    &__file-list {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__file {
        background-color: white;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 0.25rem 0.5rem 0;
        padding: 0.5rem;
        position: relative;
        transition: background-color 0.5s;
        width: 200px;

        span {
            color: black;
            display: block;
            font-size: .8rem;
            font-weight: bold;
            max-width: 100%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover, &:active, &:focus {
            background-color: $light-medium-gray;
            transition: background-color 0.5s;
        }

        &:active, &:focus {
            outline: 1px solid $heirloom-blue;
        }
    }

    &__share-icon {
        bottom: 2.5rem;
        color: $heirloom-blue !important;
        position: absolute;
        right: 1rem;
    }

    &__checkbox.form-group {
        margin-bottom: 0.5rem;
        pointer-events: none;
    }

    &__image-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 auto 0.5rem auto;
        height: 125px;
        text-align: center;
        width: 125px;

        img, svg {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__link {
        display: block;
        font-weight: bold;
        text-align: center;
    }

    &__tooltip {
        align-items: center;
        display: inline-flex;
        font-size: 1.25rem;
        font-weight: 600;
        justify-content: center;

        span {
            margin-left: 0.4rem;
        }
    }

    &__wrapper {
        min-height: calc(100vh - 65px);
        min-width: 100vw;
    }

    &__table-wrapper {
        max-width: 100%;
        overflow-x: scroll;
        width: 100%;
    }

    &__show-files {
        display: inline-block;

        select {
            height: 39px;
            transform: translateY(3px);
        }
    }
    
    table {
        margin-top: 2rem;

        tr {
            border-top: 1px solid #dee2e6;
            cursor: pointer;
        }

        th, td {
            vertical-align: middle;

            &.file-type, &.last-modified {
                display: none;
            }
        }

        th {
            font-weight: bold;
            position: relative;

            &.file-type {
                min-width: 80px;
            }

            &.last-modified {
                min-width: 115px;
            }

            &.description {
                min-width: 140px;
            }

            &.sorting {
                &::after {
                    right: 5%;
                    font-family: "Font Awesome\ 5 Free", sans-serif;
                    font-size: 1rem;
                    font-weight: 900;
                    content: "\f0de";
                    position: absolute;
                    bottom: .8em;
                    display: block;
                    opacity: .3;
                }

                &::before {
                    right: 5%;
                    font-family: "Font Awesome\ 5 Free", sans-serif;
                    font-size: 1rem;
                    font-weight: 900;
                    content: "\f0dd";
                    position: absolute;
                    bottom: .8em;
                    display: block;
                    opacity: .3;
                }
            }

            &.sorting_asc {
                &::after {
                    right: 1em;
                    font-family: "Font Awesome\ 5 Free", sans-serif;
                    font-size: 1rem;
                    font-weight: 900;
                    content: "\f0de";
                    position: absolute;
                    bottom: .9em;
                    display: block;
                    opacity: .3;
                }

                &::before {
                    right: 16px;
                    font-family: "Font Awesome\ 5 Free", sans-serif;
                    font-size: 1rem;
                    font-weight: 900;
                    content: "\f0dd";
                    position: absolute;
                    bottom: .9em;
                    display: block;
                    opacity: 1;
                }
            }

            &.sorting_desc {
                &::after {
                    right: 1em;
                    font-family: "Font Awesome\ 5 Free", sans-serif;
                    font-size: 1rem;
                    font-weight: 900;
                    content: "\f0de";
                    position: absolute;
                    bottom: .9em;
                    display: block;
                    opacity: 1;
                }

                &::before {
                    right: 16px;
                    font-family: "Font Awesome\ 5 Free", sans-serif;
                    font-size: 1rem;
                    font-weight: 900;
                    content: "\f0dd";
                    position: absolute;
                    bottom: .9em;
                    display: block;
                    opacity: .3;
                }
            }
        }

        td {
            border: none;
            font-size: .85rem;

            .file-name {
                align-items: center;
                display: flex;
                font-weight: bold;

                span {
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &.file-type {
                text-transform: uppercase;
            }

            > .form-group {
                margin-bottom: 0;
            }
        }

        .file-manager__thumbnail-wrapper {
            align-items: center;
            display: inline-flex;
            justify-content: center;
            height: 40px;
            margin-right: 0.75rem;
            width: 40px;
        }

        .file-manager__thumbnail {
            max-width: 100%;
            max-height: 100%;
        }
    }

    @media screen and (min-width: 768px) {
        &__file-list-wrapper.col-lg-9 {
            padding: 1rem calc(3rem + 15px);
        }

        table {
            th, td {
                &.file-type, &.last-modified {
                    display: table-cell;
                }
            }
        }

        &__table-wrapper {
            max-width: 100%;
            overflow-x: hidden;
            width: 100%;
        }
    }
}

.file-controls {
    margin-bottom: 1rem;

    &__buttons {
        button {
            margin-bottom: 1rem;
            margin-right: 1rem;
        }
    }

    &__view-selection {
        align-items: center;
        display: flex;

        >button {
            align-items: center;
            background-color: transparent;
            border: none;
            display: inline-flex;
            font-size: 1.25rem;
            font-weight: 600;
            margin-right: 1rem;

            svg {
                margin-right: 0.25rem;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 768px) {
        &__buttons {
            button {
                margin-bottom: 0;
            }
        }
    }
}

.sidebar {
    background-color: $light-gray;
    text-align: center;

    &__directions {
        align-items: center;
        background-color: white;
        border: $medium-gray;
        color: $medium-gray;
        display: flex;
        padding: 3rem 1rem;

        p {
            margin-bottom: 0;
        }
    }

    &__wrapper {
        margin: auto;
        max-width: 100%;
        padding: 1rem 1.5rem;
        position: sticky;
        top: 1rem;
        width: 300px;

        button {
            display: block;
            margin: 0.5rem auto;
            width: 100%;

            svg {
                margin-right: 0.5rem;
            }
        }
    }

    &__socials {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;

        &.disabled {
            opacity: .6;
            pointer-events: none;
        }
        
        button {
            pointer-events: none;
        }  
    }

    &__trash-button {
        border-top: 1px solid $light-medium-gray;
        bottom: 0;
        color: black;
        cursor: pointer;
        display: block;
        font-size: 1.3rem;
        margin-left: -15px;
        padding: 2.5rem 3rem;
        text-align: center;

        &:hover, &:active, &:focus {
            background-color: $light-medium-gray;
            color: black;
        }

        &.disabled {
            color: $light-medium-gray;
        }

        @media screen and (min-width: 992px) {
            position: fixed;
            text-align: left;
            width: 25%;
        }
    }
}

.modal {
    &.file-manager__upload-files-modal {
        .custom-close {
            float: none;
            position: absolute;
            right: 1rem;
            top: -1rem;
        }

        .modal-content {
            padding-top: 2rem;
        }

        .upload-files-modal__progress-bar, .upload-files-modal__error-message {
            margin: 8rem 0 10rem 0;
        }
    }

    .dropzone {
        &__drop-area {
            border: 1px dashed $light-medium-gray;
            display: flex;
            flex-wrap: wrap;
            height: 300px;
            justify-content: center;
            padding: 1.5rem;

            &:focus, &:active {
                border-color: black;
                outline: none;
            }

            img {
                height: auto;
                max-width: 75px; 
            }

            p {
                text-align: center;
                width: 100%;
            }
        }

        &__drop-area--active {
            border-color: $heirloom-blue;
            border-width: 2px;
        }
    }

    select {
        text-transform: capitalize;

        hr {
            display: block;
            height: 1px;
            border: 0;
            border-top: 1px solid #ccc;
            margin: 1em 0;
            padding: 0;
        }
    }
}

.access-files-modal {
    .card {
        margin-bottom: 1rem;
    }

    .card-header {
        cursor: pointer;

        &::after {
            content: '+';
            font-size: 1.6rem;
            position: absolute;
            right: .8rem;
            top: .1rem;
        }
    }

    .user-access-row {
        align-items: center;
        border-bottom: 1px solid $light-medium-gray;
        padding: 1rem .5rem;

        &:last-of-type {
            margin-bottom: 2rem;
        }
    }

    option[disabled] {
        color: $light-medium-gray;
    }
}
