@import '../../shared/styles/colors.scss';

.login-component {
    background-color: $light-gray;

    h1 {
        color: $medium-gray;
        font-size: 1.8rem;
        font-weight: normal;
        margin-bottom: 1.5rem;
    }
    
    label {
        color: $heirloom-blue;
        font-size: 0.8rem;
        font-weight: bold;
    }

    button {
        margin-top: 2rem;
    }

    .row {
        min-height: 100vh;
    }

    .form-group:nth-child(1) {
        margin-bottom: 0;
    }

    &__password-label {
        margin-top: 1rem;
    } 

    &__bg {
        background-image: url("../../shared/images/login-bg.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__logo {
        margin-bottom: 1.5rem;
        margin-top: 2rem;

        img {
            height: auto;
            max-width: 100%;
        }
    }

    &__remember {
        input {
            transform: translateY(.1rem);
        }

        label {
            color: black;
            font-size: 0.7rem;
        }
    }

    &__create-account {
        margin-top: 1rem;
    }

    &__forgot-password {
        font-size: 0.7rem;
        font-weight: bold;
    }

    &__back-button {
        display: block;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__form-wrapper {
        max-width: 20rem;
    }

    @media screen and (min-width: 768px) {
        &__logo {
            margin-top: 0;
        }
    }
}
