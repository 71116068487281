.pdf-viewer {
    &__body {
        margin-bottom: 1rem;
    }

    &__footer {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        p {
            text-align: center;
            width: 100%;
        }

        button {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.doc-viewer {
    margin-top: 1rem;
    min-height: 100vh;
    max-width: 100%;
    width: 100%;
    overflow: scroll;

    #header-bar {
        display: none;
    }
}

.heirloom-file-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;

    img {
        height: auto;
        max-width: 100%;
    }

    p {
        font-size: 1.2rem;
        max-width: 760px;
    }
}

.video-js {
    max-width: 100%;

    .vjs-big-play-button {
        top: calc(50% - .8rem) !important;
        left: calc(50% - 1.8rem) !important;
    }
}