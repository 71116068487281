@import '../../../../shared/styles/colors.scss';

.account-details {
    margin: 2rem auto;
    max-width: 60rem;

    button {
        margin-right: 1rem;
    }

    &__back-link {
        cursor: pointer;
        display: block;
        margin-bottom: 1rem;
        width: max-content;
    }

    &__heading {
        color: $heirloom-blue;
        margin-bottom: 1rem;
    }

    &__erorr {
        margin-top: 1rem;
    }

    &__statistics {
        background-color: $light-gray;
        padding: 1rem 2rem;

        dt {
            color: black;
            font-weight: 600;
        }

        dd {
            color: $heirloom-blue;
            font-size: 1.6rem;
            font-weight: 300;
            margin-bottom: 1rem;
        }
    }

    &__password-show {
        cursor: pointer;
    }

    [readonly] {
        pointer-events: none;
    }

    @media screen and (max-width: 575px) {
        [for="phone_number"] {
            margin-top: 1rem;
        }
    }
}

.account-details-header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    button {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;

        button {
            margin-right: 1rem;
        }
    }
}