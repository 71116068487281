@font-face{
    font-family: 'Source Sans 3 VF';
    font-weight: 200 900;
    font-style: normal;
    font-stretch: normal;
    src: url('WOFF2/VAR/SourceSans3VF-Roman.ttf.woff2') format('woff2'),
         url('WOFF/VAR/SourceSans3VF-Roman.ttf.woff') format('woff'),
         url('VAR/SourceSans3VF-Roman.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans 3 VF';
    font-weight: 200 900;
    font-style: italic;
    font-stretch: normal;
    src: url('WOFF2/VAR/SourceSans3VF-Italic.ttf.woff2') format('woff2'),
         url('WOFF/VAR/SourceSans3VF-Italic.ttf.woff') format('woff'),
         url('VAR/SourceSans3VF-Italic.ttf') format('truetype');
}
